import * as React from "react"
import { Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import Div100vh from 'react-div-100vh'
import Helmet from 'react-helmet';
import { Layout } from "../components/global/layout"

const Contact = ({ location }) => {
    return (
        <Layout
            pageTitle="Contact"
            metaTitle="Contact"
            ogType="website"
            canonicalUrl={location.pathname}>
            <Helmet
                bodyAttributes={{
                    class: 'is-contact'
                }}
            />
            <Div100vh className="c-contact-page">
                <div className="c-navbar__contact">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="c-navbar__contact-container">
                                <h1 className="c-navbar__contact-title">Contact</h1>
                                <Link className="c-navbar__contact-link" to="/about">Close</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <p className="c-contact-page__container">
                            <a href="mailto:hello@twocreate.co.uk">hello@twocreate.co.uk <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></a><br />
                            <a href="tel:+44(0)2038758133">+44 (0)20 3875 8133</a>
                            <br/>
                            3 Mentmore Terrace<br/>
                            London UK<br/>
                            E8 3PN<br/>
                            <a href="https://www.google.com/maps/place/Two+Create+Studio/@51.5406231,-0.057473,15z/data=!4m5!3m4!1s0x0:0xd916f3719c34cf44!8m2!3d51.5406319!4d-0.057473" rel="noopener">Map <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></a>
                        </p>
                    </div>
                    <div className="row">
                        <div className="c-contact-page__careers">
                            <h2>Careers</h2>
                            <p>
                                We're always interested in new talent.
                                <br/>
                                <a href="mailto:careers@twocreate.co.uk">careers@twocreate.co.uk <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></a>
                                <br/><br/>
                                <a href="https://www.instagram.com/two_create_studio/" rel="noreferrer" target="_blank">Instagram <svg fill="none" viewBox="0 0 17 22"><path d="m8.298 21.2 8.1-7.5-1.2-1.32-6.03 5.61V.47h-1.74v17.52l-6-5.61-1.23 1.32 8.1 7.5Z" fill="#0C0C0C" /></svg></a>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="c-contact-page__footer container-fluid">
                    <div className="row">
                        <div className="c-footer__legal">
                            <div className="c-footer__copyright">&copy; Two Create Studio {new Date().getFullYear()}</div>
                            <ul className="c-footer__legal-items">
                                <li className="c-footer__legal-item"><a href="https://www.instagram.com/two_create_studio/" className="c-footer__legal-item-link" rel="noopener">Instagram</a></li>
                                <li className="c-footer__legal-item"><a href="mailto:studio@twocreate.co.uk" className="c-footer__legal-item-link">studio@twocreate.co.uk</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Div100vh>
        </Layout>
    )
}

export default withPrismicPreview(Contact, repositoryConfigs)
